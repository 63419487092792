/**
 * Location schema (Door and Zone)
 */

import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getDoorCapacityLabel, getDoorCapacityReferences } from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { getLocationTypeReferences } from './locationType';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const locationFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the location',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const locationFielLocationType = {
  name: 'locationType',
  type: datatypes.string,
  description: 'Type of the location (DOOR/ZONE)',
  label: i18n.tc('location.fields.locationType', 1),
  references: getLocationTypeReferences(),
  rules: [rules.required],
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
};

const locationFieldFloorNumber = {
  name: 'floorNumber',
  type: datatypes.integer,
  default: 0,
  description: 'Floor number of the location.',
  label: i18n.tc('location.fields.floorNumber', 1),
  rules: [rules.required, rules.number],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
};

const locationFieldReference = {
  name: 'reference',
  type: datatypes.string,
  description: 'Reference of the location (official name)',
  default: '',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('location.fields.reference', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const locationFieldName = {
  name: 'name',
  type: datatypes.string,
  description: 'Use name of the location',
  default: '',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('location.fields.name', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const locationFieldDescription = {
  name: 'description',
  type: datatypes.string,
  description: 'Desciption the location',
  default: '',
  label: i18n.tc('location.fields.description', 1),
  minLength: 0,
  maxLength: 256,
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const locationFieldCapacity = { // Default, for Zone (adapted below for Doors)
  name: 'capacity',
  type: datatypes.integer,
  description: 'Capacity of the Zone, Group (1) or Startup(0) flag for doors.',
  label: i18n.tc('location.fields.capacity', 1),
  default: 0,
  rules: [rules.required, rules.number],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const locationFieldCapacityForDoor = {
  name: 'capacity',
  type: datatypes.integer,
  description: 'Door is a collective (1) or an office (0) one.',
  label: i18n.tc('door.fields.capacity', 1),
  default: 0,
  references: getDoorCapacityReferences(),
  rules: [rules.required, rules.number],
  valueFormat: {
    method: getDoorCapacityLabel,
  },
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleSuperAdmin],
  },
};

const locationFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('location.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const location = {
  fields: [
    locationFieldId,
    locationFielLocationType,
    locationFieldFloorNumber,
    locationFieldReference,
    locationFieldName,
    locationFieldDescription,
    locationFieldCapacity,
    locationFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleSuperAdmin],
    update: [roleAdmin, roleSuperAdmin],
    delete: [roleSuperAdmin],
  },
};

const door = {
  fields: [
    locationFieldId,
    locationFielLocationType,
    locationFieldFloorNumber,
    locationFieldReference,
    locationFieldName,
    locationFieldDescription,
    locationFieldCapacityForDoor,
    locationFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleSuperAdmin],
    update: [roleAdmin, roleSuperAdmin],
    delete: [roleSuperAdmin],
  },
};

export default location;
export {
  location,
  door,
  locationFieldId,
  locationFielLocationType,
  locationFieldFloorNumber,
  locationFieldReference,
  locationFieldName,
  locationFieldDescription,
  locationFieldCapacity,
  locationFieldCapacityForDoor,
  locationFieldItemStatus,
};
